import { ServerSpeciality, Speciality } from "apis/speciality/types";
import { ApiResponse, get } from "helpers/axios";
import { transformSpeciality } from "apis/speciality/transformers";
import { AxiosRequestConfig } from "axios";

export default function getSpecialities(
  axiosConfig?: AxiosRequestConfig
): Promise<Speciality[]> {
  return get<ApiResponse<ServerSpeciality[]>>("/specialities", axiosConfig)
    .then((response) => {
      return (
        response.data.items?.map((serverCity) =>
          transformSpeciality(serverCity)
        ) ?? []
      );
    })
    .then((response) => {
      const parsed: Speciality[] = [];

      function parseSpecialities(
        node: Speciality,
        isRootNode?: boolean,
        rootNodeName?: string,
        father?: string | null
      ) {
        if (!isRootNode) {
          parsed?.push({ ...node, rootNodeName, nestedChildren: null, father });
        }

        if (node?.nestedChildren && node?.nestedChildren.length > 0) {
          node.nestedChildren.forEach((item) =>
            parseSpecialities(
              item,
              false,
              rootNodeName,
              isRootNode ? null : node.name
            )
          );
        } else {
          return parsed;
        }
      }

      response?.forEach((item) => {
        parseSpecialities(item, true, item.name, null);
      });
      return parsed;
    });
}
