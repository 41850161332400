import React from "react";
import { NextSeo } from "next-seo";
import HomePage from "pageComponents/Home";
import { getMetaTags } from "helpers/metaTagBuilders/homePage";
import { PageWithCustomLayout } from "types/general";
import HeaderAndMenu from "components/HeaderAndMenu";
import Footer from "components/Footer";

const Index: PageWithCustomLayout = () => {
  return (
    <>
      <NextSeo {...getMetaTags()} />
      <HomePage />
    </>
  );
};

Index.getLayout = function getLayout(page) {
  return (
    <>
      <HeaderAndMenu isSearchHidden isTransparentOnDesktop />
      {page}
      <Footer />
    </>
  );
};

export default Index;
