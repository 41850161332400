import deepmerge from "deepmerge";
import { NextSeoProps } from "next-seo";

export const withOpenGraph = (tags: NextSeoProps): NextSeoProps => {
  if (tags.title || tags.description) {
    const openGraphTags: NextSeoProps = {
      openGraph: {
        title: tags.title,
        description: tags.description,
      },
    };
    return deepmerge(openGraphTags, tags);
  }
  return tags;
};
