import { useQuery } from "react-query";
import getSpecialities, { Speciality, specialityKeys } from "apis/speciality";
import { useCallback } from "react";

type UseSpecialitiesType = {
  specialities: Speciality[] | undefined;
  isLoading: boolean;
  findSpeciality: (specialityId: number) => Speciality | null;
  findSpecialityBySlug: (slug: string) => Speciality | null;
};

const useSpecialities = (): UseSpecialitiesType => {
  const { isLoading, data: specialities } = useQuery(
    specialityKeys.all,
    () => getSpecialities(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const findSpeciality = useCallback(
    (specialityId: number): Speciality | null => {
      return specialities?.find((item) => item.id === specialityId) ?? null;
    },
    [specialities]
  );

  const findSpecialityBySlug = useCallback(
    (slug: string): Speciality | null => {
      return specialities?.find((item) => item.slug === slug) ?? null;
    },
    [specialities]
  );

  return {
    isLoading,
    specialities,
    findSpeciality,
    findSpecialityBySlug,
  };
};

export default useSpecialities;
