import { NextSeoProps } from "next-seo";
import { withOpenGraph } from "./withOpenGraph";

export const getMetaTags = (): NextSeoProps => {
  return withOpenGraph({
    title:
      "Doctormood: Online Doctor Appointment Scheduling and Medical Consultation",
    description:
      "Doctormood is the easiest way to make online appointments and online medical and telephone medical consultations. With Doctormood, from the best specialists and subspecialists online, make an appointment for telephone consultation and online appointment and ask your medical questions from the doctor you want. You can also view the details of doctors, psychiatrists, clinics, hospitals and medical centers through Doctormood website.",
  });
};
