import styled, { css } from "styled-components";
import { up } from "styled-breakpoints";
import UnStyledButton from "components/Global/UnStyledButton";

export const ServiceTypes = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ServiceType = styled(UnStyledButton)<{ isActive: boolean }>`
  && {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 7px 9px;
    border-radius: 20px;
    ${({ theme, isActive }) => css`
      color: ${isActive ? "#fff" : theme.concepts.darkGrey};
      background-color: ${isActive ? theme.concepts.selected : "#F1F6FF"};
      font-weight: ${isActive ? 500 : 300};
    `}

    &:not(:first-child) {
      margin-left: 4px;

      ${up("md")} {
        margin-left: 10px;
      }
    }
  }
`;

export const ServiceTypeTitle = styled.span`
  font-size: 11px;
  text-align: center;
  margin-left: 4px;

  ${up("md")} {
    font-size: 13px;
  }
`;
