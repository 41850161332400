import React, { createContext } from "react";

export const searchTheme = {
  input: {
    height: { xs: "38px", md: "47px" },
  },
};

export type TabContextProp = {
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
};

export const TabContext = createContext<TabContextProp | null>(null);
