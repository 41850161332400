import TelephoneConsultationIcon from "svg/phone.svg";
import TextConsultationIcon from "svg/text-consult.svg";
import { ConsultationType } from "apis/consultation/types";

export const serviceTypes = [
  // {
  //   id: ConsultationType.PRESENCE,
  //   title: "clinicTurn",
  //   type: "office",
  //   icon: ClinicIcon,
  // },
  {
    id: ConsultationType.VOICE,
    title: "telephoneConsultation",
    type: "phone",
    icon: TelephoneConsultationIcon,
  },

  {
    id: ConsultationType.TEXT,
    title: "textConsultation",
    type: "text",
    icon: TextConsultationIcon,
  },
] as const;
