import React, { useCallback } from "react";
import Select from "../Select";
import { SelectProps } from "components/Inputs/Select/Select";
import { Speciality } from "apis/speciality";
import useSpecialities from "hooks/useSpecialities";
import { useTranslation } from "react-i18next";

export type SpecialitySelectProps = Omit<
  SelectProps<Speciality>,
  "items" | "isLoading" | "itemToString" | "id" | "generalName" | "value"
> & {
  id?: string;
  value: Speciality | null | string;
};

const SpecialitySelect = ({
  value,
  ...props
}: SpecialitySelectProps): React.ReactElement => {
  const { t: translate } = useTranslation("common");
  const { isLoading, specialities, findSpecialityBySlug } = useSpecialities();

  const itemToString = useCallback((speciality: Speciality | null) => {
    return speciality?.name ?? "";
  }, []);

  return (
    <Select<Speciality>
      items={specialities ?? []}
      generalName={translate("speciality")}
      id={"speciality-select"}
      itemToString={itemToString}
      isLoading={isLoading}
      value={typeof value === "string" ? findSpecialityBySlug(value) : value}
      {...props}
    />
  );
};

export default SpecialitySelect;
