import { ReactElement, useCallback, useState } from "react";
import Div from "components/Global/Div";
import { useTranslation } from "react-i18next";
import * as Styled from "pageComponents/Home/Search/AdvanceSearch/styles";
import SvgIcon from "components/SvgIcon";
import { City } from "apis/region/types";
import { Speciality } from "apis/speciality";
import SpecialitySelect from "components/Inputs/SpecialitySelect";
import { LinkButton } from "components/Button";
import { serviceTypes } from "pageComponents/SearchPage/Filters/fixture";
import { getSearchRoute } from "helpers/searchRoutes";

const AdvanceSearch = (): ReactElement => {
  const { t: translate } = useTranslation();

  const [serviceType, setServiceType] = useState(serviceTypes[0]);
  const [city, setCity] = useState<City | null>(null);
  const [speciality, setSpeciality] = useState<Speciality | null>(null);

  const handleServiceTypeChanged = useCallback(
    (item) => setServiceType(item),
    [setServiceType]
  );

  return (
    <Div flexDirection={"column"}>
      <Styled.ServiceTypes>
        {serviceTypes.map((item) => (
          <Styled.ServiceType
            key={item.id}
            onClick={() => handleServiceTypeChanged(item)}
            isActive={serviceType === item}
          >
            <SvgIcon
              component={item.icon}
              fill={"currentColor"}
              size={{ xs: "14px", md: "25px" }}
            />
            <Styled.ServiceTypeTitle>
              {translate(item.title)}
            </Styled.ServiceTypeTitle>
          </Styled.ServiceType>
        ))}
      </Styled.ServiceTypes>
      <Div flexDirection={{ md: "row", _: "column" }}>
        {/*{serviceType.id === ConsultationType.PRESENCE ? (*/}
        {/*  <Div flex={2}>*/}
        {/*    <AvailableCitySelect value={city} onChange={setCity} />*/}
        {/*  </Div>*/}
        {/*) : null}*/}
        <Div flex={3} mx={{ md: "5px", _: 0 }} my={{ md: 0, _: "10px" }}>
          <SpecialitySelect value={speciality} onChange={setSpeciality} />
        </Div>
        <Div flex={1}>
          <LinkButton
            href={getSearchRoute({
              serviceSlug: serviceType.type,
              citySlug: city?.slug,
              specialitySlug: speciality?.slug,
            })}
            style={{ width: "100%" }}
          >
            {translate("search")}
          </LinkButton>
        </Div>
      </Div>
    </Div>
  );
};

export default AdvanceSearch;
