import React, { ReactElement, useCallback, useState } from "react";
import * as Styled from "pageComponents/Home/Search/styles";
import AdvanceSearch from "pageComponents/Home/Search/AdvanceSearch";
import SimpleNameSearch from "pageComponents/Home/Search/SimpleNameSearch";
import { searchTheme, TabContext } from "pageComponents/Home/Search/fixture";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";

const Search = (): ReactElement => {
  const { t: translate } = useTranslation("home");

  const [activeTab, setActiveTab] = useState(2);

  const getTheme = useCallback(
    (current) => ({ ...current, ...searchTheme }),
    []
  );

  return (
    <ThemeProvider theme={getTheme}>
      <TabContext.Provider value={{ activeTab, setActiveTab }}>
        <Styled.Container>
          <Styled.TabButtonsContainer>
            <Styled.FirstTabButton
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              {translate("searchDoctorName")}
            </Styled.FirstTabButton>
            <Styled.SecondTabButton
              active={activeTab === 2}
              onClick={() => setActiveTab(2)}
            >
              {translate("showDoctorsList")}
            </Styled.SecondTabButton>
          </Styled.TabButtonsContainer>
          <Styled.TabsContainer>
            <Styled.TabContentWrapper
              sharpSide={"right"}
              active={activeTab === 1}
            >
              <SimpleNameSearch />
            </Styled.TabContentWrapper>
            <Styled.TabContentWrapper
              sharpSide={"left"}
              active={activeTab === 2}
            >
              <AdvanceSearch />
            </Styled.TabContentWrapper>
          </Styled.TabsContainer>
        </Styled.Container>
      </TabContext.Provider>
    </ThemeProvider>
  );
};

export default Search;
