import { ReactElement } from "react";
import * as Styled from "pageComponents/Home/styles";
import Search from "pageComponents/Home/Search";
import Div from "components/Global/Div";
import SvgIcon from "components/SvgIcon";
import PhoneIcon from "svg/phone.svg";
import TextIcon from "svg/text-consult.svg";
import DoctorsIcon from "svg/doctor-outline.svg";
import PlusIcon from "svg/plus-outline.svg";
import ResponsivePageContainer from "components/Global/ResponsivePageContainer";
import { getSearchRoute } from "helpers/searchRoutes";
import Image from "next/image";
import clinicsOverlappedFrontImage from "public/images/clinics-overlapped-front.jpg";
import clinicsOverlappedMiddleImage from "public/images/clinics-overlapped-middle.jpg";
import clinicsOverlappedBackImage from "public/images/clinics-overlapped-back.jpg";
import introduceOverlappedFrontImage from "public/images/introduce-overlapped-front.jpg";
import introduceOverlappedMiddleImage from "public/images/introduce-overlapped-middle.jpg";
import introduceOverlappedBackImage from "public/images/introduce-overlapped-back.jpg";
import { useTranslation } from "react-i18next";

export const Home = (): ReactElement => {
  const { t: translate } = useTranslation(["home", "common"]);

  return (
    <Styled.Container>
      <Styled.FirstSection>
        <Styled.SearchContainer>
          <Styled.BrandingContainer>
            <Styled.BrandName>{translate("brandName")}</Styled.BrandName>
            <Div flexDirection={{ _: "column", md: "row" }}>
              <Styled.BrandSlogan>
                {translate("brandSloganPart1")}
              </Styled.BrandSlogan>
            </Div>
          </Styled.BrandingContainer>
          <Styled.SearchBoxContainer>
            <Search />
          </Styled.SearchBoxContainer>
        </Styled.SearchContainer>
      </Styled.FirstSection>
      <Styled.BackgroundFiller color="gray">
        <ResponsivePageContainer>
          <Styled.SectionContainer>
            <Styled.ColumnSectionContentWrapper>
              <Styled.SectionTitle>
                {translate("firstSectionTitle")}
              </Styled.SectionTitle>
              <Styled.LinksContainer>
                {/*<Styled.ServiceLink*/}
                {/*  href={getSearchRoute({ serviceSlug: "office" })}*/}
                {/*>*/}
                {/*  <SvgIcon*/}
                {/*    component={ClinicIcon}*/}
                {/*    size={{ md: "25px", xs: "20px" }}*/}
                {/*  />*/}
                {/*  نوبت‌دهی مطب*/}
                {/*</Styled.ServiceLink>*/}
                <Styled.ServiceLink
                  href={getSearchRoute({ serviceSlug: "phone" })}
                >
                  <SvgIcon
                    component={PhoneIcon}
                    size={{ md: "25px", xs: "20px" }}
                  />
                  {translate("common:telephoneConsultation")}
                </Styled.ServiceLink>
                <Styled.ServiceLink
                  href={getSearchRoute({ serviceSlug: "text" })}
                >
                  <SvgIcon
                    component={TextIcon}
                    size={{ md: "25px", xs: "20px" }}
                  />
                  {translate("common:textConsultation")}
                </Styled.ServiceLink>
              </Styled.LinksContainer>
            </Styled.ColumnSectionContentWrapper>
          </Styled.SectionContainer>
        </ResponsivePageContainer>
      </Styled.BackgroundFiller>
      <Styled.BackgroundFiller color="gray">
        <ResponsivePageContainer>
          <Styled.RowSectionContainer>
            <Styled.SectionContentWrapper>
              <Styled.RightSection className="p-l">
                <Styled.SectionTitle>
                  {translate("secondSectionTitle")}
                </Styled.SectionTitle>
                <p>{translate("secondSectionDescription")}</p>
                <Styled.SectionLinks href="/doctors" primary>
                  <SvgIcon component={DoctorsIcon} size={25} />
                  {translate("secondSectionBtn")}
                </Styled.SectionLinks>
              </Styled.RightSection>
              <Styled.ImageWrapper>
                <div className="back">
                  <Image
                    src={introduceOverlappedBackImage}
                    alt={translate("imageAlts.introduceOverlappedMiddleImage")}
                  />
                </div>
                <div className="middle">
                  <Image
                    src={introduceOverlappedMiddleImage}
                    alt={translate("imageAlts.introduceOverlappedMiddleImage")}
                  />
                </div>
                <div className="front">
                  <Image
                    src={introduceOverlappedFrontImage}
                    alt={translate("imageAlts.introduceOverlappedMiddleImage")}
                  />
                </div>
              </Styled.ImageWrapper>
            </Styled.SectionContentWrapper>
          </Styled.RowSectionContainer>
        </ResponsivePageContainer>
      </Styled.BackgroundFiller>

      <ResponsivePageContainer>
        <Styled.RowSectionContainer>
          <Styled.SectionContentWrapper>
            <Styled.ImageWrapper>
              <div className="back">
                <Image
                  src={clinicsOverlappedBackImage}
                  alt={translate("imageAlts.clinicsOverlappedBackImage")}
                />
              </div>
              <div className="middle">
                <Image
                  src={clinicsOverlappedMiddleImage}
                  alt={translate("imageAlts.clinicsOverlappedBackImage")}
                />
              </div>
              <div className="front">
                <Image
                  src={clinicsOverlappedFrontImage}
                  alt={translate("imageAlts.clinicsOverlappedBackImage")}
                />
              </div>
            </Styled.ImageWrapper>
            <Styled.RightSection className="p-r">
              <Styled.SectionTitle>
                {translate("thirdSectionTitle")}
              </Styled.SectionTitle>
              <p>{translate("thirdSectionDescription")}</p>
              <Styled.SectionLinks href="/doctor/request" primary>
                <SvgIcon component={PlusIcon} size={30} />
                {translate("thirdSectionBtn")}
              </Styled.SectionLinks>
            </Styled.RightSection>
          </Styled.SectionContentWrapper>
        </Styled.RowSectionContainer>
      </ResponsivePageContainer>
      {/*<Styled.BackgroundFiller color="gray">*/}
      {/*  <ResponsivePageContainer>*/}
      {/*    <Styled.RowSectionContainer>*/}
      {/*      <Styled.SectionContentWrapper>*/}
      {/*        <Styled.RightSection className="p-l">*/}
      {/*          <Styled.SectionTitle>*/}
      {/*            {translate("forthSectionTitle")}*/}
      {/*          </Styled.SectionTitle>*/}
      {/*          <p>{translate("forthSectionDescription")}</p>*/}
      {/*          <Styled.SectionLinks href="/blog" primary target={"_blank"}>*/}
      {/*            /!*<SvgIcon component={MagazineIcon} size={25} />*!/*/}
      {/*            {translate("forthSectionBtn")}*/}
      {/*          </Styled.SectionLinks>*/}
      {/*        </Styled.RightSection>*/}
      {/*        <Styled.ImageWrapper>*/}
      {/*          <div className="single">*/}
      {/*            <Image*/}
      {/*              alt={translate("imageAlts.clinicsOverlappedBackImage")}*/}
      {/*              src={homeSection5Image}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </Styled.ImageWrapper>*/}
      {/*      </Styled.SectionContentWrapper>*/}
      {/*    </Styled.RowSectionContainer>*/}
      {/*  </ResponsivePageContainer>*/}
      {/*</Styled.BackgroundFiller>*/}
    </Styled.Container>
  );
};

export default Home;
