import React, { ReactElement, useCallback, useContext } from "react";
import { NotFoundItem, NotFoundMessage } from "components/QuickSearch/styles";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { TabContext } from "pageComponents/Home/Search/fixture";

const EmptyItem = (): ReactElement => {
  const { t: translate } = useTranslation("home");

  const tabContext = useContext(TabContext);

  const handleButtonClick = useCallback(
    () => tabContext?.setActiveTab(2),
    [tabContext]
  );

  return (
    <NotFoundItem>
      <NotFoundMessage>{translate("doctorNotFound")}</NotFoundMessage>
      <Button onClick={handleButtonClick} primary>
        {translate("showDoctorsList")}
      </Button>
    </NotFoundItem>
  );
};

export default EmptyItem;
