import styled, { css } from "styled-components";
import { up } from "styled-breakpoints";

const ACTIVE_BACKGROUND = "#fff";
const INACTIVE_BACKGROUND = "#eee";
const TAB_BORDER_RADIUS = 7;
const CURVE_WIDTH = 5;
const CURVE_RADIUS = 12;
const INACTIVE_TAB_HEIGHT = {
  xs: 40,
  md: 45,
};
const ACTIVE_TAB_HEIGHT = {
  xs: 45,
  md: 53,
};

export const Container = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // It prevents the repositioning when tabs are switched
  position: absolute;
  top: 120px;

  ${up("md")} {
    position: static;
    padding: 0;
    top: 100px;
  }
`;

export const TabButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  border: 0;
  overflow: hidden;
`;

const TabButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${INACTIVE_BACKGROUND};
  color: #545a68;

  border: 0;
  outline: none;
  padding: 0;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  height: ${INACTIVE_TAB_HEIGHT.xs}px;
  border-top-right-radius: ${TAB_BORDER_RADIUS}px;
  border-top-left-radius: ${TAB_BORDER_RADIUS}px;
  flex: 1 1 0;
  position: relative;

  ${({ active }) =>
    !active &&
    css`
      &:before {
        content: "";
        display: block;
        width: ${2 * CURVE_RADIUS}px;
        height: ${2 * CURVE_RADIUS}px;
        border-width: ${CURVE_WIDTH}px;
        border-style: solid;
        border-radius: 50%;
        border-color: transparent transparent ${ACTIVE_BACKGROUND} transparent;
        position: absolute;
        bottom: -${CURVE_WIDTH}px;
        box-sizing: content-box;
        z-index: 1;
      }
    `}

  ${up("md")} {
    font-size: 16px;
    height: ${INACTIVE_TAB_HEIGHT.md}px;
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${ACTIVE_BACKGROUND};
      color: ${theme.concepts.selected || "#4186ff"};
      height: ${ACTIVE_TAB_HEIGHT.xs}px;
      box-shadow: none;

      ${up("md")} {
        height: ${ACTIVE_TAB_HEIGHT.md}px;
      }
    `}
`;

export const FirstTabButton = styled(TabButton)`
  && {
    ${({ active }) =>
      !active &&
      css`
        border-top-right-radius: 0;

        &:before {
          transform: rotate(-45deg);
          right: 0;
          margin-right: -${CURVE_WIDTH}px;
        }
      `}
  }
`;

export const SecondTabButton = styled(TabButton)`
  ${({ active }) =>
    !active &&
    css`
      border-top-left-radius: 0;

      &:before {
        transform: rotate(45deg);
        left: 0;
        margin-left: -${CURVE_WIDTH}px;
      }
    `}
`;

export const TabsContainer = styled.div`
  //height: 75px;
  background-color: ${INACTIVE_BACKGROUND};
  border: 0;
  border-bottom-left-radius: ${TAB_BORDER_RADIUS}px;
  border-bottom-right-radius: ${TAB_BORDER_RADIUS}px;
`;

export const TabContentWrapper = styled.div<{
  active: boolean;
  sharpSide: string;
}>`
  padding: 20px 13px 20px;
  border-radius: ${TAB_BORDER_RADIUS}px;
  background-color: ${ACTIVE_BACKGROUND};
  display: ${({ active }) => (active ? "block" : "none")};

  ${({ sharpSide }) =>
    css`
      border-top-${sharpSide}-radius: 0;
    `}

  ${up("md")} {
    padding: 20px 25px;
  }
`;
