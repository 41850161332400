import React, { ReactElement, useCallback } from "react";
import QuickSearch from "components/QuickSearch";
import Div from "components/Global/Div";
import EmptyItem from "pageComponents/Home/Search/SimpleNameSearch/EmptyItem";

const SimpleNameSearch = (): ReactElement => {
  const renderEmptyItem = useCallback(() => <EmptyItem />, []);

  return (
    <Div>
      <QuickSearch
        name={"doctorsQuickSearch"}
        renderEmptyItem={renderEmptyItem}
      />
    </Div>
  );
};

export default SimpleNameSearch;
