import styled from "styled-components";
import { up } from "styled-breakpoints";
import Div from "components/Global/Div";
import { LinkButton } from "components/Button";

export const Container = styled.main`
  position: relative;
`;

type ContainerProps = {
  color?: string;
};

export const BackgroundFiller = styled.div<ContainerProps>`
  background-color: ${({ theme, color }) =>
    color === "gray" ? theme.concepts.light : "#fff"};
`;

export const FirstSection = styled.div`
  height: 100vh;
  min-height: 400px;
  position: relative;
  // prevents quick search results from being overlapped by second section
  z-index: 1;
  background-image: url("/images/home-3.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #42424a;
  display: flex;
  align-items: center;
  justify-content: center;

  ${up("md")} {
    margin-top: -75px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  ${up("md")} {
    max-width: 650px;
  }

  ${up("xl")} {
    max-width: 750px;
  }
`;

export const SearchBoxContainer = styled(Div)`
  width: 100%;
  position: absolute;
  top: -100px;

  ${up("md")} {
    top: 60px;
  }
`;

export const BrandingContainer = styled.h1`
  margin-top: -100px;
  color: #fff;
  text-align: center;
`;

export const BrandName = styled.span`
  font-weight: bold;
  font-size: 28px;
  line-height: 2;

  ${up("md")} {
    font-size: 30px;
  }
`;

export const BrandSlogan = styled.span`
  font-weight: 500;
  font-size: 19px;
  line-height: inherit;

  ${up("md")} {
    font-size: 26px;
    font-weight: 400;
  }
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5rem 0;
  /* background-color: ${({ theme }) => theme.concepts.light}; */

  ${up("sm")} {
    padding: 5rem 0;
  }
`;

export const RowSectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  position: relative;
  min-height: 90vh;

  ${up("sm")} {
    padding: 5rem 0;
  }
`;

export const SectionContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up("sm")} {
    width: 100%;
    flex-direction: row;
  }
`;

export const ColumnSectionContentWrapper = styled(SectionContentWrapper)`
  display: flex;
  align-items: unset;
  flex-direction: column;
`;

export const RightSection = styled.div`
  margin: 2rem 0;
  text-align: center;

  p {
    font-size: 120%;
    margin-bottom: 2em;
    text-align: justify;
  }

  &.p-l,
  &.p-r {
    padding-right: 0;
    padding-left: 0;
  }

  ${up("sm")} {
    flex: 1;
    text-align: left;
    &.p-l {
      padding-right: 8rem;
    }
    &.p-r {
      padding-left: 8rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  flex: 1;
  position: relative;
  order: -1;
  margin-bottom: 45px;

  .single {
    width: 100%;
  }

  .back,
  .front,
  .middle {
    border: 2px solid #fff;
  }

  .back {
    position: absolute;
    width: 50%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .middle {
    position: absolute;
    width: 50%;
    top: 60%;
    left: 0;
    transform: translateY(-50%);
  }
  .front {
    position: relative;
    width: 50%;
    margin-top: 25%;
    margin-left: 50%;
  }

  ${up("sm")} {
    order: 0;
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.h2`
  text-align: center;
  font-weight: 700;
  margin-bottom: 1em;
  font-size: 18px;

  ${up("sm")} {
    font-size: 22px;
    text-align: left;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ServiceLink = styled(LinkButton)`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: #fff !important;
  color: ${({ theme }) => theme.concepts.primary} !important;
  padding: 10px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadows.small};
  flex-grow: 1;
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }

  svg {
    margin-right: 0;
  }

  ${up("sm")} {
    padding: 2rem 3rem;
    font-size: 18px;
    flex-direction: row;
    margin-left: 30px;
    svg {
      margin-right: 1rem;
    }
  }
`;

export const SectionLinks = styled(LinkButton)`
  width: 100%;
  svg {
    margin-right: 8px;
  }

  ${up("md")} {
    width: 50%;
  }
`;
